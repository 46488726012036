import { FeaturesLoaders } from '@wix/thunderbolt-features'

export const featuresLoaders: Partial<FeaturesLoaders> = {
	autoDisplayLightbox: () =>
		import('feature-auto-display-lightbox/src/ds' /* webpackChunkName: "autoDisplayLightbox" */),

	businessLogger: () => import('feature-business-logger' /* webpackChunkName: "businessLogger" */),
	cookiesManager: () => import('feature-cookies-manager' /* webpackChunkName: "cookiesManager" */),
	platform: () => import('thunderbolt-platform/src/ds' /* webpackChunkName: "platform" */),
	ooi: () => import('feature-ooi/src/ds' /* webpackChunkName: "ooi" */),
	renderer: () => import('feature-react-renderer/src/ds' /* webpackChunkName: "renderer" */),
	components: () => import('feature-components' /* webpackChunkName: "components" */),
	consentPolicy: () => import('feature-consent-policy' /* webpackChunkName: "consentPolicy" */),
	fedopsWixCodeSdk: () => import('feature-fedops-wix-code-sdk' /* webpackChunkName: "fedopsWixCodeSdk" */),
	lightbox: () => import('feature-lightbox/src/ds' /* webpackChunkName: "popups" */),
	componentsLoader: () => import('@wix/thunderbolt-components-loader' /* webpackChunkName: "componentsLoader" */),
	componentsReact: () => import('thunderbolt-components-react' /* webpackChunkName: "componentsReact" */),
	multilingual: () => import('feature-multilingual/src/ds' /* webpackChunkName: "multilingual" */),
	translations: () => import('feature-translations/src/ds' /* webpackChunkName: "translations" */),
	siteWixCodeSdk: () => import('feature-site-wix-code-sdk/src/ds' /* webpackChunkName: "siteWixCodeSdk" */),
	windowWixCodeSdk: () => import('feature-window-wix-code-sdk' /* webpackChunkName: "windowWixCodeSdk" */),
	warmupData: () => import('feature-warmup-data' /* webpackChunkName: "warmupData" */),

	locationWixCodeSdk: () =>
		import('feature-location-wix-code-sdk/src/ds' /* webpackChunkName: "locationWixCodeSdk" */),

	navigation: () => import('feature-navigation' /* webpackChunkName: "navigation" */),
	stores: () => import('feature-stores' /* webpackChunkName: "stores" */),
	structureApi: () => import('feature-structure-api' /* webpackChunkName: "structureApi" */),
	hoverBox: () => import('feature-hover-box/src/ds' /* webpackChunkName: "hoverBox" */),
	debugDs: () => import('ds-feature-debug-ds' /* webpackChunkName: "debugDs" */),
	qaApi: () => import('feature-qa-api' /* webpackChunkName: "qaApi" */),
	testApi: () => import('feature-test-api' /* webpackChunkName: "testApi" */),
	componentsQaApi: () => import('feature-components-qa-api/src/ds' /* webpackChunkName: "componentsQaApi" */),
	imageZoom: () => import('feature-image-zoom/src/ds' /* webpackChunkName: "imageZoom" */),
	windowScroll: () => import('feature-window-scroll' /* webpackChunkName: "windowScroll" */),

	viewerManagerRendererModelApi: () =>
		import('ds-feature-viewer-manager-renderer-model-api' /* webpackChunkName: "viewerManagerRendererModelApi" */),

	viewerManagerDataApi: () =>
		import('ds-feature-viewer-manager-data-api' /* webpackChunkName: "viewerManagerDataApi" */),

	viewerManagerActionApi: () =>
		import('ds-feature-viewer-manager-action-api' /* webpackChunkName: "viewerManagerActionApi" */),

	viewerManagerLayoutApi: () =>
		import('ds-feature-viewer-manager-layout-api' /* webpackChunkName: "viewerManagerLayoutApi" */),

	navigationManager: () => import('feature-navigation-manager/src/ds' /* webpackChunkName: "navigationManager" */),

	viewerManagerDocumentServicesModelApi: () =>
		import(
			'ds-feature-viewer-manager-document-services-model-api' /* webpackChunkName: "viewerManagerDocumentServicesModelApi" */
		),

	viewerManagerModesApi: () =>
		import('ds-feature-viewer-manager-modes-api' /* webpackChunkName: "viewerManagerModesApi" */),

	viewerManagerComponentDetectorApi: () =>
		import(
			'ds-feature-viewer-manager-component-detector-api' /* webpackChunkName: "viewerManagerComponentDetectorApi" */
		),

	viewerManagerComponentApi: () =>
		import('ds-feature-viewer-manager-component-api' /* webpackChunkName: "viewerManagerComponentApi" */),

	viewerManagerStructureApi: () =>
		import('ds-feature-viewer-manager-structure-api' /* webpackChunkName: "viewerManagerStructureApi" */),

	viewerManagerRuntimeApi: () =>
		import('ds-feature-viewer-manager-runtime-api' /* webpackChunkName: "viewerManagerRuntimeApi" */),

	editorNavigation: () => import('ds-feature-editor-navigation' /* webpackChunkName: "editorNavigation" */),

	viewerManagerNavigationApi: () =>
		import('ds-feature-viewer-manager-navigation-api' /* webpackChunkName: "viewerManagerNavigationApi" */),

	viewerManagerScrollApi: () =>
		import('ds-feature-viewer-manager-scroll-api' /* webpackChunkName: "viewerManagerScrollApi" */),

	viewerManagerRealTimeConfigApi: () =>
		import(
			'ds-feature-viewer-manager-real-time-config-api' /* webpackChunkName: "viewerManagerRealTimeConfigApi" */
		),

	viewerManagerRenderFlagsApi: () =>
		import('ds-feature-viewer-manager-render-flags-api' /* webpackChunkName: "viewerManagerRenderFlagsApi" */),

	viewerManagerPagesApi: () =>
		import('ds-feature-viewer-manager-pages-api' /* webpackChunkName: "viewerManagerPagesApi" */),

	viewerManagerAnimationApi: () =>
		import('ds-feature-viewer-manager-animation-api' /* webpackChunkName: "viewerManagerAnimationApi" */),

	viewerManagerQueryApi: () =>
		import('ds-feature-viewer-manager-query-api' /* webpackChunkName: "viewerManagerQueryApi" */),

	animations: () => import('feature-animations/src/ds' /* webpackChunkName: "animations" */),
	tpa: () => import('feature-tpa/src/ds' /* webpackChunkName: "tpa" */),
	scrollToAnchor: () => import('feature-scroll-to-anchor/src/ds' /* webpackChunkName: "scrollToAnchor" */),
	tpaCommons: () => import('feature-tpa-commons/src/ds' /* webpackChunkName: "tpaCommons" */),
	dynamicPages: () => import('feature-dynamic-pages/src/ds' /* webpackChunkName: "dynamicPages" */),
	assetsLoader: () => import('feature-assets-loader/src/ds' /* webpackChunkName: "assetsLoader" */),
	router: () => import('feature-router/src/ds' /* webpackChunkName: "router" */),
	modelUpdatesInvoker: () => import('ds-feature-model-updates-invoker' /* webpackChunkName: "modelUpdatesInvoker" */),

	componentsUpdatesManager: () =>
		import('ds-feature-components-updates-manager' /* webpackChunkName: "componentsUpdatesManager" */),

	windowMessageRegistrar: () =>
		import('feature-window-message-registrar/src/ds' /* webpackChunkName: "windowMessageRegistrar" */),

	viewMode: () => import('ds-feature-view-mode' /* webpackChunkName: "viewMode" */),

	viewerManagerVariantsApi: () =>
		import('ds-feature-viewer-manager-variants-api' /* webpackChunkName: "viewerManagerVariantsApi" */),

	viewerManagerMultilingualApi: () =>
		import('ds-feature-viewer-manager-multilingual-api' /* webpackChunkName: "viewerManagerMultilingualApi" */),

	viewerManagerActionsAndBehaviorsApi: () =>
		import(
			'ds-feature-viewer-manager-actions-and-behaviors-api' /* webpackChunkName: "viewerManagerActionsAndBehaviorsApi" */
		),

	viewerManagerPathBuilderApi: () =>
		import('ds-feature-viewer-manager-path-builder-api' /* webpackChunkName: "viewerManagerPathBuilderApi" */),

	menuContainer: () => import('feature-menu-container/src/ds' /* webpackChunkName: "menuContainer" */),
	commonConfig: () => import('feature-common-config' /* webpackChunkName: "commonConfig" */),
	ooiTpaSharedConfig: () => import('feature-ooi-tpa-shared-config' /* webpackChunkName: "ooiTpaSharedConfig" */),
	sessionManager: () => import('feature-session-manager/src/ds' /* webpackChunkName: "sessionManager" */),

	viewerManagerStylableApi: () =>
		import('ds-feature-viewer-manager-stylable-api' /* webpackChunkName: "viewerManagerStylableApi" */),

	clickHandlerRegistrar: () =>
		import('feature-click-handler-registrar/src/ds' /* webpackChunkName: "clickHandlerRegistrar" */),

	coBranding: () => import('feature-co-branding' /* webpackChunkName: "coBranding" */),
	pages: () => import('feature-pages/src/ds' /* webpackChunkName: "pages" */),
	navigationPhases: () => import('feature-navigation-phases' /* webpackChunkName: "navigationPhases" */),
	freemiumBanner: () => import('ds-feature-freemium-banner' /* webpackChunkName: "freemiumBanner" */),

	viewerManagerRuntimeLayoutApi: () =>
		import('ds-feature-viewer-manager-runtime-layout-api' /* webpackChunkName: "viewerManagerRuntimeLayoutApi" */),

	viewerManagerSvgApi: () =>
		import('ds-feature-viewer-manager-svg-api' /* webpackChunkName: "viewerManagerSvgApi" */),

	viewerManagerDiagnosticsApi: () =>
		import('ds-feature-viewer-manager-diagnostics-api' /* webpackChunkName: "viewerManagerSvgApi" */),

	componentsRegistry: () => import('feature-components-registry' /* webpackChunkName: "componentsRegistry" */),
	sosp: () => import('feature-sosp/src/ds' /* webpackChunkName: "sosp" */),
	customUrlMapper: () => import('feature-custom-url-mapper' /* webpackChunkName: "customUrlMapper" */),
	platformPubsub: () => import('feature-platform-pubsub' /* webpackChunkName: "platformPubsub" */),

	viewerManagerUpdateStatusApi: () =>
		import('ds-feature-viewer-manager-update-status-api' /* webpackChunkName: "viewerManagerUpdateStatusApi" */),

	siteScrollBlocker: () => import('feature-site-scroll-blocker' /* webpackChunkName: "siteScrollBlocker" */),
	seo: () => import('feature-seo/src/ds' /* webpackChunkName: "seo" */),
	seoWixCodeSdk: () => import('feature-seo-wix-code-sdk' /* webpackChunkName: "seoWixCodeSdk" */),
	reporter: () => import('feature-reporter/src/ds' /* webpackChunkName: "reporter" */),

	viewerManagerTpaApi: () =>
		import('ds-feature-viewer-manager-tpa-api' /* webpackChunkName: "viewerManagerTpaApi" */),

	backgroundScrub: () => import('feature-background-scrub/src/ds' /* webpackChunkName: "backgroundScrub" */),
	screenIn: () => import('feature-screen-in/src/ds' /* webpackChunkName: "screenIn" */),

	animationsWixCodeSdk: () =>
		import('feature-animations-wix-code-sdk/src/ds' /* webpackChunkName: "animationsWixCodeSdk" */),

	extraSiteHeight: () => import('ds-feature-extra-site-height' /* webpackChunkName: "extraSiteHeight" */),
	tpaWorkerFeature: () => import('feature-tpa-worker' /* webpackChunkName: "tpaWorkerFeature" */),

	viewerManagerPlatformApi: () =>
		import('ds-feature-viewer-manager-platform-api' /* webpackChunkName: "viewerManagerPlatformApi" */),

	componentsPreviewTooltip: () =>
		import('ds-feature-components-preview-tooltip' /* webpackChunkName: "componentsPreviewTooltip" */),

	siteMembers: () => import('feature-site-members/src/ds' /* webpackChunkName: "siteMembers" */),
	menusCurrentPage: () => import('feature-menus-current-page' /* webpackChunkName: "menusCurrentPage" */),
	activePopup: () => import('feature-active-popup' /* webpackChunkName: "activePopup" */),

	dashboardWixCodeSdk: () =>
		import('feature-dashboard-wix-code-sdk/src/ds' /* webpackChunkName: "dashboardWixCodeSdk" */),

	viewerManagerCustomElementsApi: () =>
		import(
			'ds-feature-viewer-manager-custom-elements-api' /* webpackChunkName: "viewerManagerCustomElementsApi" */
		),

	viewerManagerMembersApi: () =>
		import('ds-feature-viewer-manager-members-api' /* webpackChunkName: "viewerManagerMembersApi" */),

	viewerManagerLivePreviewApi: () =>
		import('ds-feature-viewer-manager-live-preview-api' /* webpackChunkName: "viewerManagerLivePreviewApi" */),

	wixCustomElementComponent: () =>
		import('feature-wix-custom-element-component/src/ds' /* webpackChunkName: "wixCustomElementComponent" */),

	siteMembersWixCodeSdk: () =>
		import('feature-site-members-wix-code-sdk/src/ds' /* webpackChunkName: "siteMembersWixCodeSdk" */),

	viewerManagerDisplayedOnlyComponentsApi: () =>
		import(
			'ds-feature-viewer-manager-displayed-only-components-api' /* webpackChunkName: "viewerManagerDisplayedOnlyComponentsApi" */
		),

	pageTransitions: () => import('feature-page-transitions' /* webpackChunkName: "pageTransitions" */),
	sliderGallery: () => import('feature-slider-gallery' /* webpackChunkName: "sliderGallery" */),

	viewerManagerByRefApi: () =>
		import('ds-feature-viewer-manager-by-ref-api' /* webpackChunkName: "viewerManagerByRefApi" */),

	quickActionBar: () => import('feature-quick-action-bar' /* webpackChunkName: "quickActionBar" */),

	viewerManagerFontsApi: () =>
		import('ds-feature-viewer-manager-fonts-api' /* webpackChunkName: "viewerManagerFontsApi" */),

	viewerManagerScopesApi: () =>
		import('ds-feature-viewer-manager-scopes-api' /* webpackChunkName: "viewerManagerScopesApi" */),

	pageAnchors: () => import('feature-page-anchors/src/ds' /* webpackChunkName: "pageAnchors" */),
	componentsMeasures: () => import('ds-feature-components-measures' /* webpackChunkName: "componentsMeasures" */),
	visibility: () => import('ds-feature-visibility' /* webpackChunkName: "visibility" */),
	blockingLayer: () => import('ds-feature-blocking-layer' /* webpackChunkName: "blockingLayer" */),
	landingPage: () => import('feature-landing-page/src/ds' /* webpackChunkName: "landingPage" */),
	loginButton: () => import('feature-login-button' /* webpackChunkName: "loginButton" */),
	siteOverflow: () => import('ds-feature-site-overflow' /* webpackChunkName: "siteOverflow" */),
	measuresApi: () => import('ds-feature-measures-api' /* webpackChunkName: "measuresApi" */),
	accessibility: () => import('feature-accessibility/src/ds' /* webpackChunkName: "accessibility" */),

	viewerManagerResponsiveApi: () =>
		import('ds-feature-viewer-manager-responsive-api' /* webpackChunkName: "viewerManagerResponsiveApi" */),

	dataWixCodeSdk: () => import('feature-data-wix-code-sdk/src/ds' /* webpackChunkName: "dataWixCodeSdkEditor" */),

	viewerManagerBlocksApi: () =>
		import('ds-feature-viewer-manager-blocks-api' /* webpackChunkName: "viewerManagerBlocksApi" */),

	scrollVar: () => import('feature-scroll-var/src/ds' /* webpackChunkName: "scrollVar" */),
	pageScroll: () => import('feature-page-scroll/src/ds' /* webpackChunkName: "pageScroll" */),
	scrollRestoration: () => import('feature-scroll-restoration' /* webpackChunkName: "scrollRestoration" */),
	containerSlider: () => import('feature-container-slider' /* webpackChunkName: "containerSlider" */),
	tinyMenu: () => import('feature-tiny-menu' /* webpackChunkName: "tinyMenu" */),
	wixapps: () => import('feature-wixapps' /* webpackChunkName: "wixapps" */),
	repeaters: () => import('feature-repeaters' /* webpackChunkName: "repeaters" */),
	environment: () => import('feature-environment' /* webpackChunkName: "environment" */),

	triggersAndReactions: () =>
		import('feature-triggers-and-reactions/src/ds' /* webpackChunkName: "triggersAndReactions" */),

	motionEffects: () => import('feature-motion-effects/src/ds' /* webpackChunkName: "motionEffects" */),
	motion: () => import('feature-motion/src/ds' /* webpackChunkName: "motion" */),
	cyclicTabbing: () => import('feature-cyclic-tabbing' /* webpackChunkName: "cyclicTabbing" */),

	elementorySupportWixCodeSdk: () =>
		import('feature-elementory-support-wix-code-sdk/src/ds' /* webpackChunkName: "elementorySupportWixCodeSdk" */),

	externalComponent: () => import('feature-external-component/src/ds' /* webpackChunkName: "externalComponent" */),
	customCss: () => import('feature-custom-css/src/ds' /* webpackChunkName: "customCss" */),
	stickyToComponent: () => import('feature-sticky-to-component/src/ds' /* webpackChunkName: "stickyToComponent" */),

	viewerManagerCustomCss: () =>
		import('ds-feature-viewer-manager-custom-css' /* webpackChunkName: "viewerManagerCustomCss" */),

	nativeMobileWixCodeSdk: () =>
		import('feature-native-mobile-wix-code-sdk' /* webpackChunkName: "nativeMobileWixCodeSdk" */),

	viewerManagerVeloApi: () =>
		import('ds-feature-viewer-manager-velo-api' /* webpackChunkName: "viewerManagerVeloApi" */),

	viewerManagerExternalComponentsApi: () =>
		import(
			'ds-feature-viewer-manager-external-components-api' /* webpackChunkName: "viewerManagerExternalComponentsApi" */
		),
	routerFetch: () => import('feature-router-fetch' /* webpackChunkName: "routerFetch" */),
	activeVariantsApi: () => import('ds-feature-active-variants-api' /* webpackChunkName: "activeVariantsApi" */),
	builderComponent: () => import('feature-builder-component' /* webpackChunkName: "builderComponent" */),
}
