import { EditorName } from '@wix/thunderbolt-becky-types'

export const getEditorNameFromEditorParams = (editorName?: string): EditorName => {
	switch (editorName) {
		case 'onboarding':
			return 'Blocked'
		case 'studio':
		case 'siteHistory':
			return 'Studio'
		case 'editor_x':
			return 'EditorX'
		default:
			return 'Unknown'
	}
}
